// components/MasteryProgress.jsx
import React from 'react';
import format from 'date-fns/format';

const MasteryProgress = ({ masteryData }) => {
  const { 
    mastery_percentage, 
    current_stage,
    retention,
    next_review_date 
  } = masteryData;

  // Helper to determine progress bar color
  const getProgressColor = (percentage) => {
    if (percentage >= 80) return 'bg-green-500';
    if (percentage >= 60) return 'bg-blue-500';
    if (percentage >= 40) return 'bg-yellow-500';
    if (percentage >= 20) return 'bg-orange-500';
    return 'bg-red-500';
  };

  return (
    <div className="w-full p-6 bg-white rounded-lg shadow">
      {/* Mastery Level */}
      <div className="mb-4">
        <div className="flex justify-between mb-2">
          <span className="font-semibold text-gray-700">Mastery Level</span>
          <span className="font-bold text-gray-900">{Math.round(mastery_percentage)}%</span>
        </div>
        <div className="w-full bg-gray-200 rounded-full h-4">
          <div
            className={`h-4 rounded-full ${getProgressColor(mastery_percentage)} transition-all duration-500`}
            style={{ width: `${mastery_percentage}%` }}
          ></div>
        </div>
      </div>

      {/* Current Stage */}
      <div className="mb-4">
        <span className="text-sm text-gray-600">Current Stage: </span>
        <span className="font-semibold text-gray-900">{current_stage + 1}</span>
      </div>

      {/* Retention Info */}
      <div className="mb-4">
        <span className="text-sm text-gray-600">Current Retention: </span>
        <span className="font-semibold text-gray-900">{Math.round(retention)}%</span>
      </div>

      {/* Next Review */}
      {next_review_date && (
        <div className="text-sm text-gray-600">
          Next review: {format(new Date(next_review_date), 'MMM d, yyyy')}
        </div>
      )}
    </div>
  );
};

export default MasteryProgress;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MasteryProgress from './MasteryProgress';
import {
  getUserProfile,
  validateAccess,
  getUserLearningAccess,
  getTopicModules,
  getTopicMasteryProgress
} from '../services/api';
import { isFeatureEnabled } from '../config';
import logoImage from './images/Atomwissen_Logo.png';
import './Home.css';

// Menu Icon Component
const MenuIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="#29335C"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <line x1="3" y1="12" x2="21" y2="12"></line>
    <line x1="3" y1="6" x2="21" y2="6"></line>
    <line x1="3" y1="18" x2="21" y2="18"></line>
  </svg>
);

// User Menu Component
function UserMenu({ cfaLevel, onChangeCfaLevel, onLogout }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="user-menu-button"
      >
        <MenuIcon />
      </button>

      {isOpen && (
        <div className="user-menu-dropdown">
          <button
            onClick={() => {
              onChangeCfaLevel();
              setIsOpen(false);
            }}
            className="user-menu-item"
          >
            Change CFA Level
          </button>
          <button
            onClick={() => {
              onLogout();
              setIsOpen(false);
            }}
            className="user-menu-item"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
}

// Updated TopicItem Component
function TopicItem({ topic, onTopicSelect, onSubscribe }) {
  const handleClick = (e) => {
    e.stopPropagation(); // Prevent triggering the parent onClick
    if (topic.status === 'active') {
      onTopicSelect(topic);
    } else {
      onSubscribe(topic.id);
    }
  };

  return (
    <div className="topic-item">
      <div className="topic-header">
        <div className="topic-title">
          {topic.name}
        </div>
        <button
          onClick={handleClick}
          className={topic.status === 'active' ? 'practice-button' : 'subscribe-button'}
        >
          {topic.status === 'active' ? 'Open!' : 'Subscribe'}
        </button>
      </div>
    </div>
  );
}

function ModuleItem({ module, onStartPractice }) {
  return (
    <div className="module-item">
      <div className="module-header">
        <div className="module-title">
          {module.name}
        </div>
        <button
          onClick={() => onStartPractice(module.topicId, 'free', module.id)}
          className="module-practice-button"
        >
          Practice Now!
        </button>
      </div>
    </div>
  );
}

function TopicDetails({ 
  selectedTopic, 
  masteryStarted,
  masteryData,
  modules, 
  moduleLoading,
  moduleError,
  onStartPractice, 
  onStartMastery 
}) {
  // Check if the assisted mastery feature is enabled
  const assistedMasteryEnabled = isFeatureEnabled('ASSISTED_MASTERY');

  return (
    <div className="topic-details">
      {/* Only render the Assisted Mastery section if the feature is enabled */}
      {assistedMasteryEnabled && (
        <>
          <div className="assisted-mastery-section">
            <h2 className="section-title">Assisted Mastery</h2>
            <div className="mastery-cta">
              <button 
                className="start-mastery-button"
                onClick={() => onStartMastery(selectedTopic.id)}
              >
                {masteryStarted ? 'Continue Mastery Journey' : 'Start Your Mastery Journey'}
              </button>
            </div>
            {masteryStarted && (
              <div className="mastery-progress">
                <MasteryProgress masteryData={masteryData} />
              </div>
            )}
          </div>
          
          <div className="divider" />
        </>
      )}
      
      <div className="self-paced-section">
        <div className="modules-container">
          {moduleLoading ? (
            <div className="module-loading">Loading modules...</div>
          ) : moduleError ? (
            <div className="module-error">{moduleError}</div>
          ) : modules.length === 0 ? (
            <div className="no-modules">No modules available for this topic</div>
          ) : (
            modules.map((module) => (
              <ModuleItem
                key={module.id}
                module={module}
                onStartPractice={onStartPractice}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}

function Home() {
  // Check if the assisted mastery feature is enabled once at component level
  const assistedMasteryEnabled = isFeatureEnabled('ASSISTED_MASTERY');
  
  const [loading, setLoading] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [modules, setModules] = useState([]);
  const [moduleLoading, setModuleLoading] = useState(false);
  const [moduleError, setModuleError] = useState('');
  const [masteryStarted, setMasteryStarted] = useState(false);
  const [masteryData, setMasteryData] = useState({
    mastery_percentage: 0,
    current_stage: 0,
    retention: 0,
    next_review_date: null
  });
  const [error, setError] = useState('');
  const navigate = useNavigate();
//  const [startedMasteryTopics, setStartedMasteryTopics] = useState(new Set());

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [profile, accessData] = await Promise.all([
          getUserProfile(),
          getUserLearningAccess()
        ]);

        const formattedTopics = accessData.topics?.map((topic) => ({
          id: topic.id,
          name: topic.topic_name,
          status: accessData.access.accessible_topics?.includes(topic.id)
            ? 'active'
            : 'inactive'
        })) || [];

        setUserProfile(profile);
        setTopics(formattedTopics);
        setError('');
        setLoading(false);
      } catch (err) {
        setError(
          err.response?.data?.detail || 'Failed to load user data'
        );
        setLoading(false);
        console.error('Data fetch error:', err);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchTopicDetails = async () => {
      if (selectedTopic) {
        try {
          setModuleLoading(true);
          setModuleError('');
          
          // Get modules first
          const response = await getTopicModules(selectedTopic.id);
          const formattedModules = response.map(module => ({
            id: module.id,
            name: module.module_name,
            topicId: selectedTopic.id
          }));
          
          setModules(formattedModules);
    
          // Only try to get mastery data if mastery has started and the feature is enabled
          if (assistedMasteryEnabled && masteryStarted) {
            try {
              const masteryResponse = await getTopicMasteryProgress(selectedTopic.id);
              setMasteryData(masteryResponse);
            } catch (err) {
              console.error('Error fetching mastery data:', err);
              // Don't set an error - mastery data might not exist yet
            }
          }
          
        } catch (err) {
          console.error('Error fetching topic details:', err);
          setModuleError('Failed to load modules');
        } finally {
          setModuleLoading(false);
        }
      }
    };
  
    fetchTopicDetails();
  }, [selectedTopic, assistedMasteryEnabled, masteryStarted]);

  const handleStartPractice = async (topicId, mode, moduleId = null) => {
    try {
      const accessCheck = await validateAccess(topicId);
      if (accessCheck.has_access) {
        navigate('/question', { 
          state: { 
            topicId,
            mode: mode,
            moduleId,
            learningMode: 'free'
          }
        });
      } else {
        setError(accessCheck.message || 'Access validation failed');
      }
    } catch (err) {
      console.error('Practice start error:', err);
      setError('Failed to start practice. Please try again.');
    }
  };

  const handleTopicSelect = async (topic) => {
    setSelectedTopic(topic);
    
    // Only check mastery data if the feature is enabled
    if (assistedMasteryEnabled) {
      try {
        // Check if we have mastery data for this topic
        const masteryData = await getTopicMasteryProgress(topic.id);
        if (masteryData && masteryData.mastery_percentage > 0) {
          // setStartedMasteryTopics(prev => new Set([...prev, topic.id]));
          setMasteryStarted(true);
        } else {
          setMasteryStarted(false);
        }
      } catch (err) {
        setMasteryStarted(false);
        console.error('Error checking mastery status:', err);
      }
    }
  };

  const handleStartMastery = async (topicId) => {
    // Only execute if the feature is enabled
    if (!assistedMasteryEnabled) return;

    try {
      // First check access
      const accessCheck = await validateAccess(topicId);
      if (accessCheck.has_access) {
        // If this is first time, navigate to questions
        navigate('/question', { 
          state: { 
            topicId,
            moduleId: null,
            learningMode: 'assisted'
          }
        });
        setMasteryStarted(true);
      } else {
        setError(accessCheck.message || 'Access validation failed');
      }
    } catch (err) {
      setError('Failed to start mastery journey. Please try again.');
      console.error('Mastery start error:', err);
    }
  };

  const handleSubscribe = async (topicId) => {
    try {
      const accessData = await getUserLearningAccess();
      const formattedTopics = accessData.topics?.map((topic) => ({
        id: topic.id,
        name: topic.topic_name,
        status: accessData.access.accessible_topics?.includes(topic.id)
          ? 'active'
          : 'inactive'
      })) || [];
      setTopics(formattedTopics);
    } catch (err) {
      setError('Failed to subscribe. Please try again.');
      console.error('Subscription error:', err);
    }
  };

  const handleChangeCfaLevel = () => {
    console.log('Change CFA Level clicked');
    // Add your CFA level change logic here
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-text">Loading...</div>
      </div>
    );
  }

  return (
    <div className="home-container">
      <div className="home-header-wrapper">
        <header className="home-header">
          <div className="logo-section">
            <div className="logo-container">
              <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
            </div>
            {userProfile && (
              <div className="header-subtitle">
                CFA Level {userProfile.cfa_level}
              </div>
            )}
          </div>
          <UserMenu
            cfaLevel={userProfile?.cfa_level}
            onChangeCfaLevel={handleChangeCfaLevel}
            onLogout={handleLogout}
          />
        </header>
      </div>

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="main-content">
        <main className="topics-container">
          {topics.map((topic) => (
            <TopicItem
              key={topic.id}
              topic={topic}
              onTopicSelect={handleTopicSelect}
              onSubscribe={handleSubscribe}
            />
          ))}
        </main>
        
        <aside className="topic-details-container">
          {selectedTopic ? (
            <TopicDetails
              selectedTopic={selectedTopic}
              masteryStarted={masteryStarted}
              masteryData={masteryData}
              modules={modules}
              moduleLoading={moduleLoading}
              moduleError={moduleError}
              onStartPractice={handleStartPractice}
              onStartMastery={handleStartMastery}
            />
          ) : (
            <div className="topic-details-placeholder">
              <h2 className="section-title">Select a Topic</h2>
              <p>Choose a topic to see details and start practicing</p>
            </div>
          )}
        </aside>
      </div>
    </div>
  );
}

export default Home;
import { Atom, Infinity, BarChart2, Brain } from 'lucide-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { register } from '../services/api';
import logoImage from './images/Atomwissen_Logo.png';
import './SignUp.css';

function SignUp() {
  const [step, setStep] = useState('cta');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const requirements = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*]/.test(password)
    };

    return {
      isValid: Object.values(requirements).every(Boolean),
      requirements
    };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setError('');
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (formData.email) {
      setStep('password');
    } else {
      setError('Please enter your email address');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    const { isValid, requirements } = validatePassword(formData.password);
    if (!isValid) {
      const missingRequirements = Object.entries(requirements)
        .filter(([_, met]) => !met)
        .map(([req]) => {
          const messages = {
            length: 'at least 8 characters',
            uppercase: 'an uppercase letter',
            lowercase: 'a lowercase letter',
            number: 'a number',
            special: 'a special character (!@#$%^&*)'
          };
          return messages[req];
        })
        .join(', ');
      setError(`Password must include: ${missingRequirements}`);
      return;
    }

    try {
      setLoading(true);
      const response = await register(formData.email, formData.password);
      
      setMessage(response.detail || 'Registration successful! Please check your email to verify your account.');
      
      setFormData({
        email: '',
        password: '',
        confirmPassword: ''
      });

      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err) {
      if (err.detail?.includes('already registered')) {
        setError('This email is already registered. Please try logging in instead.');
      } else {
        setError(err.detail || 'Registration failed. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  const renderContent = () => {
    switch (step) {
      case 'cta':
        return (
          <div className="cta-container">
            <h2>A platform 100% focused<br />on practice questions</h2>
            <p>Maximize your chances of passing the CFA® exams with consistent, atomic and thoughtfully structured practice</p>
            <button 
              onClick={() => setStep('email')}
              className="cta-button"
            >
              Start Your Free Trial
            </button>
          </div>
        );
      case 'email':
        return (
          <>
            <h2>Create Your Account</h2>
            <p>Enter your email to get started</p>
            <form onSubmit={handleEmailSubmit} className="sign-up-form">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  disabled={loading}
                  required
                  className="form-input"
                />
              </div>
              {error && <div className="error-message">{error}</div>}
              <button 
                type="submit"
                disabled={loading}
                className="submit-button"
              >
                Continue
              </button>
            </form>
          </>
        );
      case 'password':
        return (
          <>
            <h2>Set Your Password</h2>
            <p>Create a secure password for your account</p>
            <form onSubmit={handleSubmit} className="sign-up-form">
              <div className="form-group email-display">
                <span>{formData.email}</span>
                <button 
                  type="button" 
                  onClick={() => setStep('email')}
                  className="text-button"
                >
                  Change
                </button>
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                  disabled={loading}
                  required
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  disabled={loading}
                  required
                  className="form-input"
                />
              </div>
              {error && <div className="error-message">{error}</div>}
              {message && <div className="success-message">{message}</div>}
              <button 
                type="submit"
                disabled={loading}
                className="submit-button"
              >
                {loading ? 'Creating Account...' : 'Create Account'}
              </button>
            </form>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="landing-container">
      {/* First Section - Sign Up */}
      <section className="landing-section signup-section">
        <div className="sign-up-container">
          <div className="sign-up-left">
            <div className="sign-up-content">
              <div className="logo-container">
                <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
              </div>
              {renderContent()}
              <div className="sign-up-footer">
                <p>
                  Already have an account?{' '}
                  <button 
                    onClick={() => navigate('/login')} 
                    className="text-button"
                  >
                    Sign In
                  </button>
                </p>
              </div>
            </div>
          </div>

          <div className="sign-up-right">
            <div 
              className="background-container"/>
            <div className="value-proposition">
              <ul>
                <li>Practice at an atomic level with unlimited exercises for every testable concept of the CFA® curriculum</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* Second Section - Why Atomwissen */}
      <section className="landing-section why-atomwissen">
        <div className="section-content">
          <h2 className="section-title">Here is how we can help you pass the CFA® Exam</h2>
          
          <div className="benefits-grid">
            <div className="benefit-card">
              <div className="benefit-icon">
                <Atom size={48} color="#1a73e8" />
              </div>
              <h3>Atomic Practice</h3>
              <p>Our practice structure is tailored to each specific learning outcome statement (LOS), with questions mapped down to granular testable concepts</p>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <Infinity size={48} color="#1a73e8" />
              </div>
              <h3>Unlimited Questions</h3>
              <p>You will never run out of practice material, out team is 100% focused on question generation and will create new questions whenever needed</p>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <BarChart2 size={48} color="#1a73e8" />
              </div>
              <h3>Progress Tracking</h3>
              <p>Monitor your improvement with detailed performance analytics and understand where to focus your efforts, for the CFA® exam every topic counts</p>
            </div>

            <div className="benefit-card">
              <div className="benefit-icon">
                <Brain size={48} color="#1a73e8" />
              </div>
              <h3>Assisted Mastery</h3>
              <p>Choose to practice by yourself or follow our Assisted Mastery algorithm, where we will guide your practice schedule for you to master those difficult topics</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SignUp;
/**
 * Feature Toggle Configuration
 * 
 * This file centralizes all feature toggles for the application.
 * Each toggle should include:
 * - A descriptive name (key)
 * - Current state (boolean)
 * - An optional description explaining the feature's purpose
 */

const FEATURES = {
    // Learning Experience Features
    ASSISTED_MASTERY: {
      enabled: false,
      description: 'Enables the assisted mastery learning mode which guides users through a structured learning path'
    },
    
    // Add other feature toggles as needed
    // EXAMPLE_FEATURE: {
    //   enabled: true,
    //   description: 'Description of what this feature does'
    // }
  };
  
  /**
   * Check if a feature is enabled
   * @param {string} featureName - The name of the feature to check
   * @param {object} context - Optional context (user, environment, etc.)
   * @returns {boolean} - Whether the feature is enabled
   */
  export const isFeatureEnabled = (featureName, context = {}) => {
    // Simple implementation - just check if the feature exists and is enabled
    if (!FEATURES[featureName]) {
      console.warn(`Feature toggle "${featureName}" not found`);
      return false;
    }
    
    return FEATURES[featureName].enabled;
  };
  
  /**
   * Get all feature toggles
   * @returns {object} - All feature toggles with their states
   */
  export const getAllFeatures = () => {
    // Create a simplified version with just the enabled state
    return Object.keys(FEATURES).reduce((result, key) => {
      result[key] = FEATURES[key].enabled;
      return result;
    }, {});
  };

  // Fix: Assign object to variable before export
  const featureExports = {
    isFeatureEnabled,
    getAllFeatures,
    // Export raw features for direct access if needed
    FEATURES
  };

  export default featureExports;
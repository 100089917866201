import React, { useState, useEffect, useCallback, Suspense } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { submitAnswer, getUserProfile, getNextQuestionFree, getNextQuestionAssisted } from '../services/api';
import logoImage from './images/Atomwissen_Logo.png';
import './Question.css';
import MasteryProgress from './MasteryProgress';

// Lazy load the formula and table renderers to reduce initial bundle size
const FormulaRenderer = React.lazy(() => import('./FormulaRenderer'));
const TableRenderer = React.lazy(() => import('./TableRenderer'));

// Loading fallback component
const LoadingComponent = ({ type }) => (
  <div className="component-loading">
    Loading {type}...
  </div>
);

const Question = () => {
  const [question, setQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [result, setResult] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [userProfile, setUserProfile] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { topicId, moduleId, learningMode } = location.state || {};

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile);
      } catch (err) {
        console.error('Error fetching user profile:', err);
      }
    };

    fetchUserProfile();
  }, []);

  const fetchQuestion = useCallback(async () => {
    try {
      if (!topicId) {
        throw new Error('No topic selected');
      }

      console.log('Fetching question with:', {  // Debug log
        topicId,
        moduleId,
        learningMode
      });
  
      setLoading(true);
      setError(null);
      setResult(null);
      setSelectedOption('');
      
      // Use different endpoints based on learning mode
      let questionData;
      if (learningMode === 'assisted') {
        console.log('Fetching assisted mode question');  // Debug log
        questionData = await getNextQuestionAssisted(topicId);
      } else {
        console.log('Fetching free mode question');  // Debug log
        questionData = await getNextQuestionFree(topicId, moduleId);
      }
      
      setQuestion(questionData);
      setStartTime(Date.now());
    } catch (err) {
      console.error('Error fetching question:', {
        message: err.message,
        response: err.response?.data,
        status: err.response?.status,
        topicId: topicId,
        learningMode: learningMode  // Add this for debugging
      });
      if (err?.message === 'No authentication token found') {
        navigate('/login');
      } else if (err?.message === 'No topic selected') {
        navigate('/');
      } else {
        setError(err?.detail || 'Failed to load question');
      }
    } finally {
      setLoading(false);
    }
  }, [topicId, moduleId, learningMode, navigate]);

  useEffect(() => {
    fetchQuestion();
  }, [fetchQuestion]);

  const handleOptionChange = (event) => {
    if (!result) {
      setSelectedOption(event.target.value);
    }
  };

  const handleSubmit = async () => {
    if (!selectedOption) {
      alert('Please select an option.');
      return;
    }
  
    const submissionData = {
      question_id: question.id,
      selected_option: selectedOption,
      time_taken: startTime ? Math.floor((Date.now() - startTime) / 1000) : null,
      learning_mode: learningMode
    };
  
    console.log('Submitting answer:', submissionData);  // For debugging
  
    try {
      setSubmitting(true);
      const submissionResult = await submitAnswer(submissionData);
      setResult(submissionResult);
    } catch (err) {
      console.error('Submit answer error:', {
        message: err.message,
        validation: err.response?.data?.detail,
        response: err.response?.data,
        status: err.response?.status,
        submissionData: submissionData
      });
      if (err?.message === 'No authentication token found') {
        navigate('/login');
      } else {
        setError(err?.detail || 'Failed to submit answer. Please try again.');
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return (
      <div className="question-container">
        <div className="status-container">
          <div className="loading-text">Loading question...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="question-container">
        <div className="status-container">
          <div className="error-text">{error}</div>
          <button 
            onClick={() => window.location.reload()}
            className="retry-button"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  if (!question) {
    return (
      <div className="question-container">
        <div className="status-container">
          <div className="loading-text">No more questions available.</div>
          <button 
            onClick={() => navigate('/')}
            className="retry-button"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="question-container">
      <div className="home-header-wrapper">
        <header className="home-header">
          <div className="logo-section">
            <div className="logo-container">
              <img src={logoImage} alt="Atomwissen Logo" className="logo-image" />
            </div>
            {userProfile && (
              <div className="header-subtitle">
                CFA Level {userProfile.cfa_level}
              </div>
            )}
          </div>
          <button 
            onClick={() => navigate('/')}
            className="return-home-button"
          >
            Return to Home
          </button>
        </header>
      </div>

      <div className="content-wrapper">
        <div className="question-header">
          {question.question_text}
        </div>

        {/* Table Rendering - Question */}
        {question.has_table && question.table_data && (
          <div className="question-table-wrapper">
            <Suspense fallback={<LoadingComponent type="table" />}>
              <TableRenderer tableData={question.table_data} />
            </Suspense>
          </div>
        )}

        <div className="options-container">
          {['A', 'B', 'C'].map((option) => {
            const isCorrectAnswer = result?.feedback ? option.toLowerCase() === result.feedback.correct_option.toLowerCase() : false;
            const isSelectedAnswer = selectedOption === option;
            
            let optionClass = 'option-button';
            if (result) {
              if (isCorrectAnswer) {
                optionClass += ' border-green-500 bg-green-50';
              } else if (isSelectedAnswer) {
                optionClass += ' border-red-500 bg-red-50';
              }
            }

            return (
              <label key={option} className={optionClass}>
                <input
                  type="radio"
                  value={option}
                  checked={selectedOption === option}
                  onChange={handleOptionChange}
                  disabled={!!result || submitting}
                  className="option-radio"
                />
                <span className="option-text">
                  {question[`option_${option.toLowerCase()}`]}
                </span>
              </label>
            );
          })}
        </div>

        {result && (
          <div className="result-container">
            <div className="result-header">
              <div className="result-icon">
                {result.feedback.is_correct ? '✓' : '✗'}
              </div>
              <div className={result.feedback.is_correct ? 'correct-answer' : 'incorrect-answer'}>
                {result.feedback.is_correct ? 'Correct!' : 'Incorrect'}
              </div>
            </div>

            {/* Mastery Progress - Only show in assisted mode */}
            {result.mastery_info && (
              <div className="mastery-info-box mb-4">
                <MasteryProgress masteryData={result.mastery_info} />
              </div>
            )}

            {!result.feedback.is_correct && (
              <div className="explanation-box">
                <div className="section-title">Correct Answer:</div>
                <div className="section-content">
                  {question[`option_${result.feedback.correct_option.toLowerCase()}`]}
                </div>
              </div>
            )}

            {result.feedback.explanation && (
              <div className="explanation-box">
                <div className="section-title">Explanation:</div>
                <div className="section-content">{result.feedback.explanation}</div>
              </div>
            )}

            {/* Formula Rendering - Feedback */}
            {result.feedback.has_formula && result.feedback.formula_data && (
              <div className="feedback-formula-wrapper">
                <Suspense fallback={<LoadingComponent type="formula" />}>
                  <FormulaRenderer formula={result.feedback.formula_data} displayMode={true} />
                </Suspense>
              </div>
            )}

            {result.feedback.concept_description && (
              <div className="concept-box">
                <div className="section-title">Key Concept:</div>
                <div className="section-content">{result.feedback.concept_description}</div>
              </div>
            )}
          </div>
        )}

        <button 
          onClick={result ? fetchQuestion : handleSubmit}
          disabled={!result && (submitting || !selectedOption)}
          className="action-button"
        >
          {result ? 'Next Question →' : (submitting ? 'Submitting...' : 'Submit Answer')}
        </button>
      </div>
    </div>
  );
};

export default Question;